<template>
	<div class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<CCard class="p-4">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(verifyOTP)">
										<h1 class="pb-4 text-center">
											{{ $t("ForgotPwd.Title") }}
										</h1>
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_otp"
												:rules="`requiredExport`"
											>
												<div class="form-group row">
													<label for="otp" class="col-md-3 col-12 col-form-label">{{
														$t("OTP.Field")
													}}</label>
													<div :class="['col-12 col-md-9', classes]">
														<input
															v-model.trim="otp"
															type="text"
															class="form-control"
															:placeholder="$t('OTP.Field')"
															autocomplete="otp"
															:maxlength="AUTH_ROLE.OTP"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow>
											<CCol col="6" class="text-left">
												<button class="px-4 btn btn-outline-dark" type="button" @click="resendOTP">
													{{ $t("OTP.Resend") }}
												</button>
											</CCol>
											<CCol col="6" class="text-right">
												<button class="px-4 btn btn-outline-custom" type="submit">
													{{ $t("OTP.Continue") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import { AUTH_ROLE, AUTH_TYPE } from "@/shared/plugins/constants"
import { SEND_OTP, VERIFY_OTP } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "SendOTP",
	data() {
		return {
			otp: null,
			AUTH_ROLE,
			type: AUTH_TYPE.RESET_PASSWORD,
		}
	},
	computed: {
		...mapState(["loginForm", "OTPErr", "email"]),
	},
	watch: {
		OTPErr(val) {
			if (val) this.$refs.form.setErrors(val)
		},
	},

	methods: {
		...mapActions({ SEND_OTP, VERIFY_OTP }),
		verifyOTP: async function () {
			const params = { otp: this.otp, email: this.email }
			await this.VERIFY_OTP(params)
		},
		resendOTP: async function () {
			const params = {
				email: this.email,
				type: this.type,
			}
			await this.SEND_OTP({ ...params })
			this.otp = null
			this.$refs.form.reset()
		},
	},
}
</script>
